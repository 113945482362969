<template>
  <el-form
      class="dense-form"
      label-position="top"
      size="mini"
      v-loading="loading"
  >
    <div class="row">
      <div class="col-3">
        <el-form-item
            :label="$t('system.vehicle_no')"
            required
        >
          <el-input
              :class="{'validate-error': !!backendErrors['vehicle_no']}"
              v-model="form.vehicle_no"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['vehicle_no']"
          >
            {{ $t(backendErrors['vehicle_no'] ? backendErrors['vehicle_no'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item
            :label="$t('system.name')"
            required
        >
          <el-input
              :class="{'validate-error': !!backendErrors['name']}"
              v-model="form.name"
          />
          <div
              class="el-form-item__error"
              v-if="!!backendErrors['name']"
          >
            {{ $t(backendErrors['name'] ? backendErrors['name'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item
            :label="$t('system.make')"
            required
        >
          <el-input
              :class="{'validate-error': !!backendErrors['make']}"
              v-model="form.make"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['make']"
          >
            {{ $t(backendErrors['make'] ? backendErrors['make'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item
            :label="$t('system.model')"
            required
        >
          <el-input
              :class="{'validate-error': !!backendErrors['model']}"
              v-model="form.model"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['model']"
          >
            {{ $t(backendErrors['model'] ? backendErrors['model'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item
            :label="$t('system.availability')"
        >
          <el-switch
              :class="{'validate-error': !!backendErrors['availability']}"
              v-model="form.availability"
          ></el-switch>
          <div
              class="el-form-item__error"
              v-if="backendErrors['model']"
          >
            {{ $t(backendErrors['availability'] ? backendErrors['availability'][0] : '') }}
          </div>
        </el-form-item>
      </div>
      <div class="col-3">
        <el-form-item
            :label="$t('system.i_pass')"
            required
        >
          <el-input
              :class="{'validate-error': !!backendErrors['i_pass']}"
              v-model="form.i_pass"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['i_pass']"
          >
            {{ $t(backendErrors['i_pass'] ? backendErrors['i_pass'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item
            :label="$t('system.plate')"
            required
        >
          <el-input
              :class="{'validate-error': !!backendErrors['plate']}"
              v-model="form.plate"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['plate']"
          >
            {{ $t(backendErrors['plate'] ? backendErrors['plate'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item
            :label="$t('system.registered_at')"
            required
        >
          <el-date-picker
              :class="{'validate-error': !!backendErrors['registered_at']}"
              format="yyyy-MM-dd"
              v-model="form.registered_at"
              value-format="yyyy-MM-dd"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['registered_at']"
          >
            {{ $t(backendErrors['registered_at'] ? backendErrors['registered_at'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item
            :label="$t('system.wiper_blades')"
            required
        >
          <el-input
              :class="{'validate-error': !!backendErrors['wiper_blades']}"
              v-model="form.wiper_blades"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['wiper_blades']"
          >
            {{ $t(backendErrors['wiper_blades'] ? backendErrors['wiper_blades'][0] : '') }}
          </div>
        </el-form-item>
      </div>
      <div class="col-3">
        <el-form-item :label="$t('system.year')">
          <el-input
              :class="{'validate-error': !!backendErrors['year']}"
              v-model="form.year"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['year']"
          >
            {{ $t(backendErrors['year'] ? backendErrors['year'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('system.last_safety_inspection')">
          <el-date-picker
              :class="{'validate-error': !!backendErrors['last_safety_inspection']}"
              class="w-100"
              format="yyyy-MM-dd"
              type="daterange"
              v-model="lastSafetyInspection"
              value-format="yyyy-MM-dd"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['last_safety_inspection']"
          >
            {{ $t(backendErrors['last_safety_inspection'] ? backendErrors['last_safety_inspection'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('system.emissions_tested_at')">
          <el-date-picker
              :class="{'validate-error': !!backendErrors['emissions_tested_at']}"
              format="yyyy-MM-dd"
              v-model="form.emissions_tested_at"
              value-format="yyyy-MM-dd"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['emissions_tested_at']"
          >
            {{ $t(backendErrors['emissions_tested_at'] ? backendErrors['emissions_tested_at'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('system.oil_due')">
          <el-date-picker
              :class="{'validate-error': !!backendErrors['oil_due']}"
              format="yyyy-MM-dd"
              v-model="form.oil_due"
              value-format="yyyy-MM-dd"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['oil_due']"
          >
            {{ $t(backendErrors['oil_due'] ? backendErrors['oil_due'][0] : '') }}
          </div>
        </el-form-item>
      </div>
      <div class="col-3">
        <el-form-item :label="$t('system.vin')">
          <el-input
              :class="{'validate-error': !!backendErrors['vin']}"
              v-model="form.vin"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['vin']"
          >
            {{ $t(backendErrors['vin'] ? backendErrors['vin'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('system.mileage_due')">
          <el-input
              :class="{'validate-error': !!backendErrors['mileage_due']}"
              v-model="form.mileage_due"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['mileage_due']"
          >
            {{ $t(backendErrors['mileage_due'] ? backendErrors['mileage_due'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('system.external_id')">
          <el-input
              :class="{'validate-error': !!backendErrors['external_id']}"
              v-model="form.external_id"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['external_id']"
          >
            {{ $t(backendErrors['external_id'] ? backendErrors['external_id'][0] : '') }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('system.max_height')">
          <el-input
              :class="{'validate-error': !!backendErrors['max_height']}"
              v-model="form.max_height"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['max_height']"
          >
            {{ $t(backendErrors['max_height'] ? backendErrors['max_height'][0] : '') }}
          </div>
        </el-form-item>
      </div>
      <div class="col-12">
        <el-form-item :label="$t('system.note')">
          <el-input
              :class="{'validate-error': !!backendErrors['note']}"
              rows="4"
              type="textarea"
              v-model="form.note"
          />
          <div
              class="el-form-item__error"
              v-if="backendErrors['note']"
          >
            {{ $t(backendErrors['note'] ? backendErrors['note'][0] : '') }}
          </div>
        </el-form-item>
      </div>
    </div>
    <el-form-item class="d-flex justify-end">
      <el-button
          :loading="saveLoading"
          @click="save"
          type="primary"
      >
        {{ $t('system.save') }}
      </el-button>
      <el-button @click="close">{{ $t('system.cancel') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import axios from 'axios';
import {mapObjToObj} from '../../../helpers/functions';
import notification from '../../../notification/notify';

export default {
  name: 'VehicleForm',
  props: {
    id: {
      type: [String, Number],
    },
  },
  data: () => ({
    backendErrors: {},
    saveLoading: false,
    loading: false,
    form: {
      vehicle_no: null,
      name: null,
      vin: null,
      year: null,
      make: null,
      model: null,
      availability: true,
      i_pass: null,
      registered_at: null,
      plate: null,
      last_safety_inspection_from: null,
      last_safety_inspection_to: null,
      emissions_tested_at: null,
      oil_due: null,
      mileage_due: null,
      external_id: null,
      max_height: null,
      wiper_blades: null,
      note: null,
    },
  }),
  created() {
    if (this.id) {
      this.fetchData();
    }
  },
  computed: {
    lastSafetyInspection: {
      set(value) {
        const [from, to] = value;
        this.form.last_safety_inspection_from = from;
        this.form.last_safety_inspection_to = to;
      },
      get() {
        if (this.form.last_safety_inspection_from) {
          return [this.form.last_safety_inspection_from, this.form.last_safety_inspection_to];
        }
        return null;
      },
    },
  },
  methods: {
    getBackendErrors(field) {
      return this.backendErrors[field];
    },
    close(updateList = false) {
      this.$emit('close', updateList);
    },
    async save() {
      try {
        this.saveLoading = true;
        const method = this.id ? 'put' : 'post';
        await axios[method](`workorders/vehicles${this.id ? `/${this.id}` : ''}`, this.form);
        this.close(true);
        notification.notify(
            this.$t('notify.success'),
            this.$t('system.saved'),
            'success');
      } catch (error) {
        this.backendErrors = error?.response?.data || {};
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_save'),
            'error');
      } finally {
        this.saveLoading = false;
      }
    },
    async fetchData() {
      try {
        this.loading = true;
        const {data} = await axios.get(`workorders/vehicles/${this.id}`);
        this.form = mapObjToObj(this.form, data);
        this.form.availability ? this.form.availability = true : this.form.availability = false;
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
